import { mapGetters, mapMutations, mapActions } from "vuex"
import { getHeadersForTable, getFootersForTable } from "@/utils/table"
import { TABLE_NAMES, VALIDATION_ERROR, MAX_CHARACTER_LIMIT } from "@/constants"

export default {
  name: "ReporterIntakeForms",
  data() {
    return {
      showNewReporterIntakeFormDialog: false,
      newReporterIntakeFormName      : undefined,
      formTemplateNameCharacterLimit : MAX_CHARACTER_LIMIT.REPORTER_FORM_TEMPLATE_NAME
    }
  },
  methods: {
    ...mapActions({
      addFormTemplate: "formTemplates/addFormTemplate",
      notify         : "shared/notify"
    }),
    ...mapMutations({
      setFormTemplateAddError: "formTemplates/setFormTemplateAddError"
    }),
    cancelNewReporterIntakeForm() {
      this.newReporterIntakeFormName       = undefined
      this.showNewReporterIntakeFormDialog = false
    }
  },
  computed: {
    ...mapGetters({
      formTemplates       : "formTemplates/formTemplates",
      isFormTemplateAdded : "formTemplates/isFormTemplateAdded",
      formTemplateAddError: "formTemplates/formTemplateAddError",
      isAddingFormTemplate: "formTemplates/isAddingFormTemplate"
    }),
    headersForTable() {
      return getHeadersForTable(TABLE_NAMES.REPORTER_FORM_TEMPLATES, this.$t.bind(this))
    },
    footersForTable() {
      return getFootersForTable(TABLE_NAMES.REPORTER_FORM_TEMPLATES, this.$t.bind(this))
    },
    isIntakeFormNameDuplicate() {
      if(this.formTemplateAddError) {
        return this.formTemplateAddError.field === VALIDATION_ERROR.FIELD.NAME &&
          this.formTemplateAddError.type === VALIDATION_ERROR.TYPE.DUPLICATE
      }
    },
    isReporterIntakeFormNameValid() {
      return this.newReporterIntakeFormName &&
      this.newReporterIntakeFormName.length <= this.formTemplateNameCharacterLimit
    }
  },
  watch: {
    newReporterIntakeFormName: {
      handler: function() {
        this.setFormTemplateAddError(undefined)
      }
    },
    isFormTemplateAdded: {
      handler: function(value) {
        if (value) {
          this.notify({
            type      : "success",
            text      : "765",
            parameters: {
              name: this.newReporterIntakeFormName
            }
          })
          this.newReporterIntakeFormName       = undefined
          this.showNewReporterIntakeFormDialog = false
        }
      }
    }
  }
}